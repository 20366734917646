<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true ,right:'历史反馈',feedback:true}"></v-header>
        </div>
        <div class="top" @click="goPage('feedbackSubmit')">
            <div class="left">
                <img src="../../assets/images/feedback_chat.png" alt="">
                <span>我要反馈</span>
            </div>
            <div class="right">
                <img src="../../assets/images/right_arrow.png" alt="">
            </div>
        </div>
        <div class="question_list">
            <span>热门问题</span>
            <div class="question" v-for="(item,index) in list" :key="index">
                <div class="question_item" @click="goPage('feedbackItem?content='+item.content+'&header='+item.title)" >
                    <p>{{item.title}}</p>
                    <img src="../../assets/images/right_arrow.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$.meta.title,
            list:[
                {
                    title:'登录时，提示设备超出限制',
                    content:'1、这种情况是因为您的账号在多台手机上登录导致的，请在已经登录过的手机设备上退出，新的手机才可以登录'+'<br>'+'2、点击联系我们->联系管理员'
                },
                {
                    title:'长条及其他图片打不开怎么办',
                    content:'1、检查网络是否连接，查看手机设备的硬件功能和网速是否达到要求'+'<br>'+'2、点击联系我们->联系管理员'
                },
                {
                    title:'为什么要实名认证',
                    content:'兑换收益，是需要实名认证的，平台需要遵守法律法规，严格控制和监管，在保证用户信息真实有效的前提下，才能兑换收益功能'
                },
                {
                    title:'为什么连中那么多期，还没成为大师',
                    content:'每期中奖后，系统会统计您近10期的发帖数据，本期单向类型回报率排行榜20名可进入大师排行榜'
                }
            ]
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100vh;
        background-color: #eee;
        position: absolute;
        top:0;
        .top{
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            margin: 10px 0;
            background-color: #fff;
            .left{
                img{
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    padding: 0 6px;
                }
            }
            .right{
                img{
                    width: 20px;
                    height: 20px;
                    padding: 0 10px;
                }
            }
            &:active{
                background-color: #ddd;
            }
        }
        .question_list{
            width: 100%;
            span{
                display: block;
                padding: 4px 0 8px 6px;
                font-size: 18px;
            }
            .question{
                background-color: #fff;
                .question_item{
                    border-bottom: 1px solid #ccc;
                    p{
                        display: inline-block;
                        padding-left: 10px;
                        font-size: 16px;
                    }
                    img{
                        width: 20px;
                        height: 20px;
                        padding: 15px 10px;
                        float: right;
                    }
                }
                &:first-of-type{
                    border-top: 1px solid #ccc;
                }
            }
        }

    }
</style>